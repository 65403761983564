import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { getRoute } from '../config/routes.config';
import { SideBarNavItem } from '../config/sidebar.config';
import Logo from '../images/laatupankki-logo-2.png';
import { BackgroundClass } from './BackgroundClass';

export const classNames = (...classes: string[]) => {
  return classes.filter((x) => x).join(' ');
};

type ChildrenElement = JSX.Element | string | null | (JSX.Element | string | null)[];

export type DashbaordNavItem = {
  name: string;
  href: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  current: boolean;
  preventActive?: boolean;
  exact?: boolean;
};

type Props = {
  children: JSX.Element | JSX.Element[];
  homeUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  nav: SideBarNavItem[];
};

type SidebarLinkProps = {
  to: string;
  children: ChildrenElement;
  baseClass: string;
  exact?: boolean;
  onClick(): void;
};

const SidebarLink = (props: SidebarLinkProps) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: props.exact || false });

  const notActiveClass = 'text-primary hover:bg-primary hover:text-white';
  const activeClass = 'text-primary font-bold hover:bg-primary hover:text-white';

  let dynamicClass = notActiveClass;

  if (match) dynamicClass = activeClass;

  return (
    <Link
      onClick={props.onClick}
      className={[dynamicClass, props.baseClass].join(' ')}
      to={props.to}
    >
      {props.children}
    </Link>
  );
};

type DropdownProps = {
  children: ChildrenElement;
  to: string;
  baseClass: string;
  onClick(): void;
  links: SideBarNavItem[];
};

const Dropdown = (props: DropdownProps) => {
  const { t } = useTranslation('SideBar');
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: false });

  const notActiveClass = 'text-primary hover:bg-primary hover:text-white';
  const activeClass = notActiveClass + ' font-bold';
  const dropDownBase = 'ml-12';

  let dynamicClass = notActiveClass;

  if (match) dynamicClass = activeClass;

  return (
    <Disclosure as="div" className="space-y-1">
      {() => (
        <>
          <Disclosure.Button
            className={[dynamicClass, props.baseClass.replace('w-auto', 'w-full')].join(' ')}
          >
            {props.children}
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            {props.links.map((subItem) => (
              <Disclosure.Button
                key={subItem.href}
                as={Link}
                onClick={props.onClick}
                to={subItem.href}
                className={[dropDownBase, notActiveClass, props.baseClass].join(' ')}
              >
                <>
                  {subItem.icon ? (
                    <subItem.icon
                      className={classNames('mr-3 flex-shrink-0 h-6 w-6')}
                      aria-hidden="true"
                    />
                  ) : null}
                  {t(subItem.name)}
                </>
              </Disclosure.Button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export const DashboardLayout = (props: Props) => {
  const { children, nav } = props;
  const { t } = useTranslation('SideBar');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <BackgroundClass className="bg-gray-100" />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-primary" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="ml-5">
                    <Link to={getRoute('DASHBOARD')}>
                      <img className="h-10 w-auto" src={Logo} alt="Laatupankki logo" />
                    </Link>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {nav.map((item) =>
                      !('links' in item) ? (
                        <SidebarLink
                          exact={item.exact}
                          onClick={() => setSidebarOpen(false)}
                          key={item.name}
                          to={item.href}
                          baseClass="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        >
                          {item.icon ? (
                            <item.icon
                              className={classNames('mr-4 flex-shrink-0 h-6 w-6')}
                              aria-hidden="true"
                            />
                          ) : null}
                          {t(item.name)}
                        </SidebarLink>
                      ) : (
                        <Dropdown
                          links={item.links}
                          baseClass="group flex items-center px-2 py-2 text-base font-medium rounded-md w-auto"
                          key={item.name}
                          to={item.href}
                          onClick={() => setSidebarOpen(false)}
                        >
                          {item.icon ? (
                            <item.icon
                              className={classNames('mr-3 flex-shrink-0 h-6 w-6')}
                              aria-hidden="true"
                            />
                          ) : null}
                          {t(item.name)}
                        </Dropdown>
                      )
                    )}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="m-auto">
                <Link to={getRoute('DASHBOARD')}>
                  <img className="h-8 w-auto" src={Logo} alt="Laatupankki logo" />
                </Link>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {nav.map((item) =>
                  !('links' in item) ? (
                    <SidebarLink
                      exact={item.exact}
                      onClick={() => setSidebarOpen(false)}
                      baseClass="group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      key={item.name}
                      to={item.href}
                    >
                      {item.icon ? (
                        <item.icon
                          className={classNames('mr-3 flex-shrink-0 h-6 w-6 ')}
                          aria-hidden="true"
                        />
                      ) : null}
                      {t(item.name)}
                    </SidebarLink>
                  ) : (
                    <Dropdown
                      links={item.links}
                      baseClass="group flex items-center px-2 py-2 text-sm font-medium rounded-md w-auto"
                      key={item.name}
                      to={item.href}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <item.icon
                        className={classNames('mr-3 flex-shrink-0 h-6 w-6')}
                        aria-hidden="true"
                      />
                      {t(item.name)}
                    </Dropdown>
                  )
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow md:hidden">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="m-auto">
              <div className="-ml-10">
                <Link to={getRoute('DASHBOARD')}>
                  <img className="h-8 w-auto" src={Logo} alt="Laatupankki logo" />
                </Link>
              </div>
            </div>
          </div>
          <main className="flex-1">
            <div className="p-2 sm:p-3" style={{ paddingBottom: '80px' }}>
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

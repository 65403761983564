import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthLayout } from '../../../components/auth/AuthLayout';
import { authConfig } from '../../../config/auth.config';
import { getRoute } from '../../../config/routes.config';
import { loginForm } from '../../../forms/login.form';
import { LoginMutation, useLoginMutation } from '../../../generated/graphql';
import Logo from '../../../images/laatupankki-logo-2.png';
import { useOnComplete } from '../../../releox-engine/on-complete/use-on-complete';
import { AppFormGenerator } from '../../../releox-engine/react-components/app-form-generator/AppFormGenerator';
import { useSubmitHandler } from '../../../releox-engine/use-submit-handler/use-submit-handler';

export const LoginScene = () => {
  const { t } = useTranslation('LoginScene');

  const loginUser = useCallback((data: LoginMutation) => {
    localStorage.setItem(authConfig.tokenStorageKey, data.login.token);
  }, []);

  const onComplete = useOnComplete('DASHBOARD', null, loginUser);

  const [login, { loading }] = useLoginMutation({
    onCompleted: onComplete,
  });

  const handleLogin = useSubmitHandler(login);

  const FooterElement = (
    <div className="grid grid-cols-2 pt-3">
      <Link
        to={getRoute('FORGOT')}
        className="text-primary hover:text-secondary block text-sm my-auto"
      >
        {t('forgotPassword')}
      </Link>
      <div className="text-right">
        <Button loading={loading} type="submit">
          {t('login')}
        </Button>
      </div>
    </div>
  );

  return (
    <AuthLayout>
      <div className="mb-4">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Laatupankki logo" />
      </div>
      <AuthCard>
        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-900">{t('login')}</h2>
          <p className="mt-1 text-sm text-gray-600">{t('fillInformation')}</p>
        </div>
        <AppFormGenerator
          form={loginForm}
          onSubmit={handleLogin}
          wrapperClassNames="space-y-3"
          FooterElement={FooterElement}
        />
      </AuthCard>
    </AuthLayout>
  );
};

import { get } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { useDocumentColumn } from '../../columns/document.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import ContentEditor from '../../components/ContentEditor';
import { DocumentApprovers } from '../../components/DocumentApprovers';
import { DocumentDetails } from '../../components/DocumentDetails';
import { Loading } from '../../components/Loading';
import { MessageForm } from '../../components/MessageForm';
import { SimpleHistory } from '../../components/SimpleHistory';
import { DocumentApprovalAction } from '../../components/approve-system/DocumentApprovalAction';
import { DSDocumentLinks } from '../../components/document-show/DSDocumentLinks';
import { DSFiles } from '../../components/document-show/DSFiles';
import { getDocEditRoute, getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { viewPrivilegeAtom } from '../../contexes/view-privilege.atom';
import {
  useDeleteDocumentLinkMutation,
  useDeleteDriveFileMutation,
  useDocumentShowSceneQuery,
} from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const DocumentShowScene = ({ type }: Props): JSX.Element => {
  const documentColumn = useDocumentColumn();
  const [user] = useContext(UserContext);
  const viewPrev = useRecoilValue(viewPrivilegeAtom);
  const { t } = useTranslation('DocumentShowScene');
  const { id } = useParams();
  if (!id) throw new Error('Missing ID');
  const { data, refetch } = useDocumentShowSceneQuery({
    variables: { id },
  });

  const [deleteDocumentLink] = useDeleteDocumentLinkMutation({
    onCompleted: (d) => {
      if (d.deleteDocumentLink) {
        toast.success(t('Common:deleted'));
        refetch();
      }
    },
  });

  const [deleteDriveFile] = useDeleteDriveFileMutation({
    onCompleted: (d) => {
      if (d.deleteDriveFile) {
        toast.success(t('Common:deleted'));
        refetch();
      }
    },
  });

  const EDIT = getDocEditRoute(type);

  if (!data) return <Loading />;

  const hasEditPrivilege: boolean =
    user.myCustomDocuments.edit.includes(data.document.documentModel.id) ||
    get(viewPrev, `${type}.update`);

  const hasCreatePrivilege: boolean =
    user.myCustomDocuments.create.includes(data.document.documentModel.id) ||
    get(viewPrev, `${type}.create`);

  const hasCreateOrEditPrivilege: boolean = hasCreatePrivilege || hasEditPrivilege;

  return (
    <div className="grid lg:grid-cols-2" key={type}>
      <div className="md:mr-3">
        <Card>
          {hasEditPrivilege ? (
            <ButtonLink className="float-right" to={getRoute(EDIT, { id })}>
              {t('edit')}
            </ButtonLink>
          ) : (
            <></>
          )}
          <CardTitle>
            #<>{data.document.documentIdentifier}</> - {data.document.name}
          </CardTitle>

          <>
            {data.document.tags.map((tag) => (
              <span
                key={tag.name}
                className="inline-block text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-blue-100 text-blue-800"
              >
                {tag.name}
              </span>
            ))}
          </>

          <div className="mt-4">
            <DocumentDetails
              document={data.document}
              columns={[
                documentColumn.status,
                documentColumn.name,
                documentColumn.createdBy,
                documentColumn.createdAt,
                documentColumn.groupNames,
                documentColumn.description,
              ]}
            />
          </div>
          <DocumentApprovalAction refetch={refetch} data={data} type={type} />
        </Card>
        <DocumentApprovers
          refetch={refetch}
          data={data}
          type={type}
          id={id}
          isAllowed={hasCreateOrEditPrivilege}
        />
        {data.document.miroLink ? (
          <Card className="mt-3">
            <iframe
              title="miro"
              className="w-full"
              height="432"
              src={data.document.miroLink}
              frameBorder="0"
              scrolling="no"
              allowFullScreen
            ></iframe>
          </Card>
        ) : null}

        <DSDocumentLinks
          data={data}
          type={type}
          hasCreateOrEditPrivilege={hasCreateOrEditPrivilege}
          handleDelete={(linkId) => {
            if (confirm(t('confirmLinkDelete'))) {
              deleteDocumentLink({ variables: { id: linkId } });
            }
          }}
        />

        <DSFiles
          hasCreateOrEditPrivilege={hasCreateOrEditPrivilege}
          data={data}
          refetch={refetch}
          handleDelete={() => {
            if (confirm(t('confirmFileDelete'))) {
              deleteDriveFile({ variables: { id: data.document.driveFiles[0].id } });
            }
          }}
          type={type}
        />
      </div>
      <div className="space-y-4">
        {data.document.msLink ? (
          <Card className="mb-3">
            <iframe
              title="Microsoft iframe"
              className="w-full"
              height="600"
              src={data.document.msLink}
              frameBorder="0"
            >
              Tämä on liitetty{' '}
              <a target="_blank" href="https://office.com" rel="noreferrer">
                Microsoft Office
              </a>{' '}
              tiedosto, jonka tarjoaa{' '}
              <a target="_blank" href="https://office.com/webapps" rel="noreferrer">
                Office
              </a>
              .
            </iframe>
          </Card>
        ) : null}
        <Card>
          <ContentEditor
            documentContent={data.document.documentContent}
            id={id}
            type={type}
            hasEditPermission={hasCreateOrEditPrivilege}
          />
        </Card>
        <Card>
          <SimpleHistory history={data.logs} />
          <>
            {hasCreateOrEditPrivilege && (
              <MessageForm refetch={refetch} documentId={data.document.id} />
            )}
          </>
        </Card>
      </div>
    </div>
  );
};

import * as Yup from 'yup';
import { RiskCreateFormQuery } from '../../generated/graphql';

type X = RiskCreateFormQuery['riskCreateForm']['fields'];

export const createSchemaFromForm = (type: 'create' | 'edit', x: X) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shape: any = {};

  for (const field of x) {
    if (field.type === 'text') {
      shape[field.name] = Yup.string();
    }

    if (field.type === 'select') {
      shape[field.name] = Yup.string();
    }

    if (field.type === 'multi-select') {
      shape[field.name] = Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      );
    }

    if (field.type === 'react-select') {
      shape[field.name] = Yup.string();
    }

    if (field.type === 'checkbox') {
      shape[field.name] = Yup.boolean();
    }

    if (field.type === 'number') {
      shape[field.name] = Yup.number();
    }

    if (field.type === 'date') {
      shape[field.name] = Yup.string();
    }

    if (field.type === 'inline-select') {
      shape[field.name] = Yup.string();
    }

    if (field.isRequired) {
      shape[field.name] = shape[field.name].required();
    }
  }

  shape['isApproveRequired'] = Yup.boolean().required();
  shape['approverId'] = Yup.string().when('isApproveRequired', ([value], schema) => {
    if (value) {
      return schema.required();
    }
    return schema.optional();
  });

  if (type === 'edit') {
    delete shape['approverId'];
  }

  return Yup.object().shape(shape);
};

import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'DocumentLink:name',
    type: 'text',
  },
};
const url: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'url',
    label: 'DocumentLink:url',
    type: 'text',
  },
};

export const documentLinkInput = {
  name,
  url,
};

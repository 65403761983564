import { DocumentTypeField } from '../utils/DocumentType';

const routesConfig = {
  DASHBOARD: '/',

  LOGIN: '/kirjaudu',
  LOGOUT: '/kirjaudu-ulos',
  FORGOT: '/unohdin-salasanani',
  FORGOT_SUCCESS: '/salasanan-vaihto-linkki-lähetetty',
  RESET_PASSWORD: '/vaihda-salasana',
  RESET_PASSWORD_SUCCESS: '/salasanan-vaihot-onnistui',
  CONFIRM: '/vahvista-tunnus',
  REGISTER: '/rekisteroidy',
  PRIVACY: '/rekisteriseloste',
  ACCOUNT_DELETE: '/poista-tunnus',
  ACCEPT_INVITATION: '/hyvaksy-kutsu',

  PUBLIC_PAGE: '/p/:id',
  PUBLIC_THANKS: '/p/:id/kiitos',

  DOCUMENT_INDEX: '/dokumentit',
  DOCUMENT_CREATE: '/dokumentit/luo',
  DOCUMENT_SHOW: '/dokumentit/:id',
  DOCUMENT_MIRO: '/dokumentit/:id/miro',
  DOCUMENT_MS: '/dokumentit/:id/ms',
  DOCUMENT_EDIT: '/dokumentit/:id/muokkaa',
  DOCUMENT_EDITOR: '/dokumentit/:id/editori',
  DOCUMENT_LINKS_CREATE: '/dokumentit/:id/linkit/luo',
  DOCUMENT_LINKS_EDIT: '/dokumentit/:id/linkit/:linkId/edit',
  DOCUMENT_DRIVE_FILE_EDIT: '/dokumentit/:id/tiedostot/:driveFileId',
  DOCUMENT_REJECT: '/dokumentit/:id/hylkaa/:documentApproverId',
  DOCUMENT_SEND_REMINDER: '/dokumentit/:id/laheta-muistutus/:documentApproverId',
  DOCUMENT_ADD_APPROVER: '/dokumentit/:id/lisaa-hyvaksyja',

  QNC_INDEX: '/poikkeamat',
  QNC_CREATE: '/poikkeamat/luo',
  QNC_EDIT: '/poikkeamat/:id/muokkaa',
  QNC_EDITOR: '/poikkeamat/:id/editori',
  QNC_SHOW: '/poikkeamat/:id',
  QNC_MIRO: '/poikkeamat/:id/miro',
  QNC_MS: '/poikkeamat/:id/ms',
  QNC_LINKS_CREATE: '/poikkeamat/:id/linkit/luo',
  QNC_LINKS_EDIT: '/poikkeamat/:id/linkit/:linkId/edit',
  QNC_DRIVE_FILE_EDIT: '/poikkeamat/:id/tiedostot/:driveFileId',
  QNC_REJECT: '/poikkeamat/:id/hylkaa/:documentApproverId',
  QNC_SEND_REMINDER: '/poikkeamat/:id/laheta-muistutus/:documentApproverId',
  QNC_ADD_APPROVER: '/poikkeamat/:id/lisaa-hyvaksyja',

  RISK_INDEX: '/riskit',
  RISK_SHOW: '/riskit/:id',
  RISK_MIRO: '/riskit/:id/miro',
  RISK_MS: '/riskit/:id/ms',
  RISK_CREATE: '/riskit/luo',
  RISK_EDITOR: '/riskit/:id/editori',
  RISK_EDIT: '/riskit/:id/muokkaa',
  RISK_LINKS_CREATE: '/riskit/:id/linkit/luo',
  RISK_LINKS_EDIT: '/riskit/:id/linkit/:linkId/edit',
  RISK_DRIVE_FILE_EDIT: '/riskit/:id/tiedostot/:driveFileId',
  RISK_REJECT: '/riskit/:id/hylkaa/:documentApproverId',
  RISK_SEND_REMINDER: '/riskit/:id/laheta-muistutus/:documentApproverId',
  RISK_ADD_APPROVER: '/riskit/:id/lisaa-hyvaksyja',

  ANONYMOUS_INDEX: '/anonyymit',
  ANONYMOUS_SHOW: '/anonyymit/:id',
  ANONYMOUS_MIRO: '/anonyymit/:id/miro',
  ANONYMOUS_MS: '/anonyymit/:id/ms',
  ANONYMOUS_CREATE: '/anonyymit/luo',
  ANONYMOUS_EDITOR: '/anonyymit/:id/editori',
  ANONYMOUS_EDIT: '/anonyymit/:id/muokkaa',
  ANONYMOUS_LINKS_CREATE: '/anonyymit/:id/linkit/luo',
  ANONYMOUS_LINKS_EDIT: '/anonyymit/:id/linkit/:linkId/edit',
  ANONYMOUS_DRIVE_FILE_EDIT: '/anonyymit/:id/tiedostot/:driveFileId',
  ANONYMOUS_REJECT: '/anonyymit/:id/hylkaa/:documentApproverId',
  ANONYMOUS_SEND_REMINDER: '/anonyymit/:id/laheta-muistutus/:documentApproverId',
  ANONYMOUS_ADD_APPROVER: '/anonyymit/:id/lisaa-hyvaksyja',

  REPORT_INDEX: '/raportit',
  REPORT_QNC_COUNT: '/raportit/qnc-count',
  REPORT_QNC_COST: '/raportit/qnc-cost',
  REPORT_QNC_TYPE: '/raportit/qnc-type',
  REPORT_QNC_TAG: '/raportit/qnc-tag',
  REPORT_RISK_MATRIX: '/raportit/risk-matrix',

  USER_EDIT: '/asetukset/muokkaa-kayttajaa',
  COMPANY_EDIT: '/asetukset/muokkaa-yritysta',
  SETTINGS_ROOT: '/asetukset',

  DOCUMENT_MODEL_INDEX: '/asetukset/dokumentti-pohjat',
  DOCUMENT_MODEL_EDIT: '/asetukset/dokumentti-pohjat/:id/muokkaa',
  DOCUMENT_MODEL_CREATE: '/asetukset/dokumentti-pohjat/luo',

  GROUP_INDEX: '/asetukset/ryhmat',
  GROUP_CREATE: '/asetukset/ryhmat/luo',
  GROUP_EDIT: '/asetukset/ryhmat/:id/muokkaa',
  GROUP_SHOW: '/asetukset/ryhmat/:id',

  TAG_INDEX: '/asetukset/tags',
  TAG_CREATE: '/asetukset/tags/luo',
  TAG_EDIT: '/asetukset/tags/:id/muokkaa',

  CUSTOM_DOCUMENT_INDEX: '/lomakkeet',
  CUSTOM_DOCUMENT_CHOOSE: '/lomakkeet/valitse-pohja',
  CUSTOM_DOCUMENT_CREATE: '/lomakkeet/:documentModelId/luo',
  CUSTOM_DOCUMENT_EDIT: '/lomakkeet/:id/muokkaa',
  CUSTOM_DOCUMENT_SHOW: '/lomakkeet/:id',
  CUSTOM_DOCUMENT_MIRO: '/lomakkeet/:id/miro',
  CUSTOM_DOCUMENT_MS: '/lomakkeet/:id/ms',
  CUSTOM_DOCUMENT_EDITOR: '/lomakkeet/:id/editori',
  CUSTOM_DOCUMENT_LINKS_CREATE: '/lomakkeet/:id/linkit/luo',
  CUSTOM_DOCUMENT_LINKS_EDIT: '/lomakkeet/:id/linkit/:linkId/edit',
  CUSTOM_DOCUMENT_DRIVE_FILE_EDIT: '/lomakkeet/:id/tiedostot/:driveFileId',
  CUSTOM_DOCUMENT_REJECT: '/lomakkeet/:id/hylkaa/:documentApproverId',
  CUSTOM_DOCUMENT_SEND_REMINDER: '/lomakkeet/:id/laheta-muistutus/:documentApproverId',
  CUSTOM_DOCUMENT_ADD_APPROVER: '/lomakkeet/:id/lisaa-hyvaksyja',

  CUSTOM_REPORT_SHOW: '/raportit/mukautettu/:id',
  CUSTOM_REPORT_CREATE: '/raportit/mukautettu/luo',

  TEAM_INDEX: '/asetukset/kayttajat',
  TEAM_EDIT: '/asetukset/kayttajat/:id/muokkaa',
  TEAM_INVITE: '/asetukset/kayttajat/kutsu',

  EXPORT: '/asetukset/vie',
  DATA_VALIDATION: '/data-validation',

  ACL_INDEX: '/asetukset/kayttooikeudet',
  ACL_SHOW: '/asetukset/kayttooikeudet/:id',
  ACL_CREATE: '/asetukset/kayttooikeudet/luo',
  ACL_EDIT: '/asetukset/kayttooikeudet/:id/muokkaa',
};

type Key = keyof typeof routesConfig;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoute = (arg: Key, params?: any, skipValidation = false): string => {
  let url = routesConfig[arg];

  if (params) {
    const keys = Object.keys(params);

    const paramsLength = (url.match(/:/g) || []).length;

    if (!skipValidation) {
      if (keys.length !== paramsLength) {
        // eslint-disable-next-line no-console
        console.error('[getRoute] params and arguments mismatch', {
          arg,
          url,
          params,
          paramsLength,
        });
        throw new Error('[getRoute] params and arguments mismatch');
      }
    }

    keys.forEach((p) => {
      url = url.replace(`:${p}`, params[p]);
    });
  }

  if (url.includes(':')) throw new Error(`[getRoute] ${url} route missing params`);

  return url;
};

export const getDashboardRouteConfig = () => {
  return routesConfig;
};

type SendReminder =
  | 'DOCUMENT_SEND_REMINDER'
  | 'QNC_SEND_REMINDER'
  | 'RISK_SEND_REMINDER'
  | 'CUSTOM_DOCUMENT_SEND_REMINDER'
  | 'ANONYMOUS_SEND_REMINDER';

type RejectKey =
  | 'DOCUMENT_REJECT'
  | 'QNC_REJECT'
  | 'RISK_REJECT'
  | 'CUSTOM_DOCUMENT_REJECT'
  | 'ANONYMOUS_REJECT';

type IndexKey =
  | 'DOCUMENT_INDEX'
  | 'QNC_INDEX'
  | 'RISK_INDEX'
  | 'CUSTOM_DOCUMENT_INDEX'
  | 'ANONYMOUS_INDEX';

type ShowKey =
  | 'DOCUMENT_SHOW'
  | 'QNC_SHOW'
  | 'RISK_SHOW'
  | 'CUSTOM_DOCUMENT_SHOW'
  | 'ANONYMOUS_SHOW';

type CreateKey =
  | 'DOCUMENT_CREATE'
  | 'QNC_CREATE'
  | 'RISK_CREATE'
  | 'CUSTOM_DOCUMENT_CHOOSE'
  | 'ANONYMOUS_CREATE';

type DriveFileEditKey =
  | 'DOCUMENT_DRIVE_FILE_EDIT'
  | 'QNC_DRIVE_FILE_EDIT'
  | 'RISK_DRIVE_FILE_EDIT'
  | 'CUSTOM_DOCUMENT_DRIVE_FILE_EDIT'
  | 'ANONYMOUS_DRIVE_FILE_EDIT';

type EditKey =
  | 'DOCUMENT_EDIT'
  | 'QNC_EDIT'
  | 'RISK_EDIT'
  | 'CUSTOM_DOCUMENT_EDIT'
  | 'ANONYMOUS_EDIT';

type ApproverKey =
  | 'DOCUMENT_ADD_APPROVER'
  | 'QNC_ADD_APPROVER'
  | 'RISK_ADD_APPROVER'
  | 'CUSTOM_DOCUMENT_ADD_APPROVER'
  | 'ANONYMOUS_ADD_APPROVER';

type EditorKey =
  | 'DOCUMENT_EDITOR'
  | 'QNC_EDITOR'
  | 'RISK_EDITOR'
  | 'CUSTOM_DOCUMENT_EDITOR'
  | 'ANONYMOUS_EDITOR';

type MiroKey =
  | 'DOCUMENT_MIRO'
  | 'QNC_MIRO'
  | 'RISK_MIRO'
  | 'CUSTOM_DOCUMENT_MIRO'
  | 'ANONYMOUS_MIRO';

type MSKey = 'DOCUMENT_MS' | 'QNC_MS' | 'RISK_MS' | 'CUSTOM_DOCUMENT_MS' | 'ANONYMOUS_MS';

type CreateLinkKey =
  | 'DOCUMENT_LINKS_CREATE'
  | 'QNC_LINKS_CREATE'
  | 'RISK_LINKS_CREATE'
  | 'CUSTOM_DOCUMENT_LINKS_CREATE'
  | 'ANONYMOUS_LINKS_CREATE';

type EditLinkKey =
  | 'DOCUMENT_LINKS_EDIT'
  | 'QNC_LINKS_EDIT'
  | 'RISK_LINKS_EDIT'
  | 'CUSTOM_DOCUMENT_LINKS_EDIT'
  | 'ANONYMOUS_LINKS_EDIT';

export const getDocShowRoute = (type: DocumentTypeField): ShowKey => {
  if (type === 'document') return 'DOCUMENT_SHOW';
  if (type === 'qnc') return 'QNC_SHOW';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_SHOW';
  if (type === 'risk') return 'RISK_SHOW';
  if (type === 'anonymous') return 'ANONYMOUS_SHOW';

  throw new Error('Invalid document type');
};

export const getDocCreateMiroRoute = (type: DocumentTypeField): MiroKey => {
  if (type === 'document') return 'DOCUMENT_MIRO';
  if (type === 'qnc') return 'QNC_MIRO';
  if (type === 'risk') return 'RISK_MIRO';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_MIRO';
  if (type === 'anonymous') return 'ANONYMOUS_MIRO';

  throw new Error('Invalid document type');
};

export const getDocAddApproverRoute = (type: DocumentTypeField): ApproverKey => {
  if (type === 'document') return 'DOCUMENT_ADD_APPROVER';
  if (type === 'qnc') return 'QNC_ADD_APPROVER';
  if (type === 'risk') return 'RISK_ADD_APPROVER';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_ADD_APPROVER';
  if (type === 'anonymous') return 'ANONYMOUS_ADD_APPROVER';

  throw new Error('Invalid document type');
};

export const getDocCreateMSRoute = (type: DocumentTypeField): MSKey => {
  if (type === 'document') return 'DOCUMENT_MS';
  if (type === 'qnc') return 'QNC_MS';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_MS';
  if (type === 'risk') return 'RISK_MS';
  if (type === 'anonymous') return 'ANONYMOUS_MS';

  throw new Error('Invalid document type');
};

export const getDocEditorRoute = (type: DocumentTypeField): EditorKey => {
  if (type === 'document') return 'DOCUMENT_EDITOR';
  if (type === 'qnc') return 'QNC_EDITOR';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_EDITOR';
  if (type === 'risk') return 'RISK_EDITOR';
  if (type === 'anonymous') return 'ANONYMOUS_EDITOR';

  throw new Error('Invalid document type');
};

export const getDocCreateRoute = (type: DocumentTypeField): CreateKey => {
  if (type === 'document') return 'DOCUMENT_CREATE';
  if (type === 'qnc') return 'QNC_CREATE';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_CHOOSE';
  if (type === 'risk') return 'RISK_CREATE';
  if (type === 'anonymous') return 'ANONYMOUS_CREATE';

  throw new Error('Invalid document type');
};

export const getDocEditRoute = (type: DocumentTypeField): EditKey => {
  if (type === 'document') return 'DOCUMENT_EDIT';
  if (type === 'qnc') return 'QNC_EDIT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_EDIT';
  if (type === 'risk') return 'RISK_EDIT';
  if (type === 'anonymous') return 'ANONYMOUS_EDIT';

  throw new Error('Invalid document type');
};

export const getDocDriveFileEditRoute = (type: DocumentTypeField): DriveFileEditKey => {
  if (type === 'document') return 'DOCUMENT_DRIVE_FILE_EDIT';
  if (type === 'qnc') return 'QNC_DRIVE_FILE_EDIT';
  if (type === 'risk') return 'RISK_DRIVE_FILE_EDIT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_DRIVE_FILE_EDIT';
  if (type === 'anonymous') return 'ANONYMOUS_DRIVE_FILE_EDIT';

  throw new Error('Invalid document type');
};

export const getCreateLinkRoute = (type: DocumentTypeField): CreateLinkKey => {
  if (type === 'document') return 'DOCUMENT_LINKS_CREATE';
  if (type === 'qnc') return 'QNC_LINKS_CREATE';
  if (type === 'risk') return 'RISK_LINKS_CREATE';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_LINKS_CREATE';
  if (type === 'anonymous') return 'ANONYMOUS_LINKS_CREATE';

  throw new Error('Invalid document type');
};

export const getEditLinkRoute = (type: DocumentTypeField): EditLinkKey => {
  if (type === 'document') return 'DOCUMENT_LINKS_EDIT';
  if (type === 'qnc') return 'QNC_LINKS_EDIT';
  if (type === 'risk') return 'RISK_LINKS_EDIT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_LINKS_EDIT';
  if (type === 'anonymous') return 'ANONYMOUS_LINKS_EDIT';

  throw new Error('Invalid document type');
};

export const getDocIndexRoute = (type: DocumentTypeField): IndexKey => {
  if (type === 'document') return 'DOCUMENT_INDEX';
  if (type === 'qnc') return 'QNC_INDEX';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_INDEX';
  if (type === 'risk') return 'RISK_INDEX';
  if (type === 'anonymous') return 'ANONYMOUS_INDEX';

  throw new Error('Invalid document type');
};

export const getDocRejectRoute = (type: DocumentTypeField): RejectKey => {
  if (type === 'document') return 'DOCUMENT_REJECT';
  if (type === 'qnc') return 'QNC_REJECT';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_REJECT';
  if (type === 'risk') return 'RISK_REJECT';
  if (type === 'anonymous') return 'ANONYMOUS_REJECT';

  throw new Error('Invalid document type');
};

export const getDocSendReminderRoute = (type: DocumentTypeField): SendReminder => {
  if (type === 'document') return 'DOCUMENT_SEND_REMINDER';
  if (type === 'qnc') return 'QNC_SEND_REMINDER';
  if (type === 'custom') return 'CUSTOM_DOCUMENT_SEND_REMINDER';
  if (type === 'risk') return 'RISK_SEND_REMINDER';
  if (type === 'anonymous') return 'ANONYMOUS_SEND_REMINDER';

  throw new Error('Invalid document type');
};

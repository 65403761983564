import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { userColumn } from '../../columns/user.columns';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { EmptyTable } from '../../components/table/EmptyTable';
import { LoadingTable } from '../../components/table/LoadingTable';
import { Pagination } from '../../components/table/Pagination';
import { Table } from '../../components/table/Table';
import { TableBody } from '../../components/table/TableBody';
import { TableCol } from '../../components/table/TableCol';
import { TableHead } from '../../components/table/TableHead';
import { TableLayout } from '../../components/table/TableLayout';
import { TableRow } from '../../components/table/TableRow';
import { TableTH } from '../../components/table/TableTH';
import { getRoute } from '../../config/routes.config';
import { TeamFilterForm } from '../../forms/filters/team-filter/team-filter.form';
import { useUserIndexSceneQuery } from '../../generated/graphql';
import { useOrderBy } from '../../hooks/index/use-order-by';
import { usePerPage } from '../../hooks/index/use-per-page';
import { useSkip } from '../../hooks/index/use-skip';

export const TeamIndexScene = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('TeamIndexScene');
  const perPage = usePerPage();
  const { orderBy, orderDir } = useOrderBy();
  const skip = useSkip();

  const columns = [
    userColumn.name,
    userColumn.email,
    userColumn.isApprover,
    userColumn.groups,
    userColumn.acl,
    userColumn.createdAt,
  ];

  const isApprover = useMemo(() => {
    if (searchParams.get('isApprover') === 'true') return true;
    if (searchParams.get('isApprover') === 'false') return false;
    return null;
  }, [searchParams]);

  const { data, loading } = useUserIndexSceneQuery({
    variables: {
      where: {
        name: searchParams.get('name'),
        email: searchParams.get('email'),
        resourceACLId: searchParams.get('resourceACLId'),
        groupId: searchParams.get('groupId'),
        isApprover: isApprover,
      },
      orderBy: [
        {
          [orderBy]: orderDir,
        },
      ],
      take: perPage,
      skip: skip,
    },
  });

  return (
    <TableLayout
      Filter={<TeamFilterForm />}
      Table={
        <Card>
          <ButtonLink to={getRoute('TEAM_INVITE')} className="float-right">
            {t('Common:addNew')}
          </ButtonLink>
          <CardTitle>{t('title')}</CardTitle>
          <Table>
            <TableHead>
              <tr>
                {columns.map((column) => (
                  <TableTH key={`${column.field}-${column.text}`} column={column} />
                ))}
              </tr>
            </TableHead>
            <TableBody>
              <LoadingTable columns={columns} loading={loading} />
              <EmptyTable columns={columns} data={data?.users.list} loading={loading} />
              {data?.users.list.map((user) => (
                <TableRow key={user.id} href={getRoute('TEAM_EDIT', { id: user.id })}>
                  {columns.map((column) => (
                    <TableCol key={`${column.field}-${column.text}`} data={user} column={column} />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination total={data?.users.totalCount || 0} />
        </Card>
      }
    />
  );
};
